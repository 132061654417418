















import { Component, Vue } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue {
  private hoy = new Date();
  private fecha = this.hoy.getFullYear();
}
