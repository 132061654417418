

























































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import { Debounce } from "vue-debounce-decorator";

interface dataBusqueda {
  dataActividades: Array<any> | null;
  dataNoticias: Array<any> | null;
  dataUnidades: Array<any> | null;
  dataLinks: Array<any> | null;
}

@Component
export default class Buscador extends Mixins(FormValidator) {
  private win = window;
  private busquedaData: any = null;
  private links: any = null;

  @Watch("$store.state.modalBusqueda")
  private watchModalBusqueda(newValue: any) {
    if (!newValue) {
      this.cerrar();
    }
  }

  @Watch("$store.state.textBusqueda")
  private watchInput(newValue: string) {
    if (newValue && newValue !== "") {
      this.buscar(newValue);
    } else {
      this.busquedaData = null;
    }
  }

  get fontExterno() {
    if (this.$q.screen.xs) {
      return "15";
    }
    return "20";
  }
  get fontTitulo() {
    if (this.$q.screen.xs) {
      return "35";
    }
    return "40";
  }
  get fontTexto() {
    if (this.$q.screen.xs) {
      return "11";
    }
    return "16";
  }
  private mounted() {
    this.busquedaData = null;
    this.links = null;
  }

  @Debounce(500)
  private buscar(value: any) {
    this.$axios
      .get("buscar?text=" + value)
      .then((res: any) => {
        this.busquedaData = res.data;
        for (let i = 0; i < this.busquedaData.dataConvenios.length; i++) {
          if (
            this.busquedaData.dataConvenios[i].imagen &&
            this.busquedaData.dataConvenios[i].imagen != ""
          ) {
            this.busquedaData.dataConvenios[i].imagen =
              "https://colina-docs.tarjetavecino.com/" +
              this.busquedaData.dataConvenios[i].imagen;
          } else {
            this.busquedaData.dataConvenios[i].imagen =
              "https://colina-docs.tarjetavecino.com/logo/default.png";
          }
        }

        for (let i = 0; i < this.busquedaData.dataEmprendedores.length; i++) {
          if (
            this.busquedaData.dataEmprendedores[i].imagen &&
            this.busquedaData.dataEmprendedores[i].imagen != ""
          ) {
            this.busquedaData.dataEmprendedores[i].imagen =
              "https://colina-docs.tarjetavecino.com/" +
              this.busquedaData.dataEmprendedores[i].imagen;
          } else {
            this.busquedaData.dataEmprendedores[i].imagen =
              "https://colina-docs.tarjetavecino.com/logo/default.png";
          }
        }
      })
      .catch((error: any) => {
        this.busquedaData = null;
        console.log(error);
      });
  }

  private cerrar() {
    this.$store.commit("SET_MODAL_BUSCADOR", false);
    this.busquedaData = {};
  }

  private irEmprendedor(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "DetalleEmp" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$store.commit("SET_MODAL_BUSCADOR", false);
      this.$router.go(0);
    } else if(
      this.$router.currentRoute.name == "DetalleEmp" &&
      this.$router.currentRoute.params.name != nombreWeb) {
      this.$store.commit("SET_MODAL_BUSCADOR", false);
      this.$router
        .push({
          name: "DetalleEmp",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
        this.$router.go(0);
    }else{
      this.$router
        .push({
          name: "DetalleEmp",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private irConvenio(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Beneficio" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Beneficio",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }

  private compare(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  }
}
